
import { btnName } from '@/utils/index'
import CTable from '@/components/c-table'
import ModifyDialog from './modify-dialog.vue'
import { getTableConfig, getIndexConfig } from './config'
import CSearchList from '@/components/c-search-list'
import { defineComponent, reactive, toRefs } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { getRoleList, deleteRole } from './api'
import moment from 'moment'

export default defineComponent({
  components: { CSearchList, CTable, ModifyDialog },

  setup() {
    // 表单组件变量
    const tableConfig = reactive({
      ...getTableConfig()
    })

    // 页面基础变量
    const data = reactive({
      ...getIndexConfig()
    })

    // 列表数据
    const getListFn = () => {
      const params = {
        platformCode: 1,
        currPage: tableConfig.pagination.currentPage,
        pageSize: tableConfig.pagination.pageSize
      }
      const obj = Object.assign(params, data.queryForm)
      tableConfig.loading = true
      getRoleList(obj)
        .then(res => {
          tableConfig.data = res.data.list
          tableConfig.data.map(item => {
            item.updateTime = moment(item.updateTime).format(
              'YYYY-MM-DD HH:mm:ss'
            )
            item.roleStatusTxt = item.roleStatus ? '正常' : '禁用'
          })
          tableConfig.pagination.total = res.data.total
        })
        .finally(() => {
          tableConfig.loading = false
        })
    }

    // 表单设置页数
    const pageSizeChange = (val: number) => {
      tableConfig.pagination.currentPage = 1
      tableConfig.pagination.pageSize = val
      getListFn()
    }

    // 表单翻页
    const pageCurrentChange = (val: number) => {
      tableConfig.pagination.currentPage = val
      getListFn()
    }

    // 执行搜索
    const handleSearch = (params: object) => {
      data.queryForm = params
      getListFn()
    }

    // 执行重置
    const handleReset = (params: object) => {
      data.queryForm = params
    }

    // 表单操作栏按钮
    const btnHandle = (type: string, row: any) => {
      switch (type) {
        case 'add': {
          data.title = '新建角色'
          data.rowData = {}
          data.visible = true
          break
        }
        case 'edit': {
          data.title = '修改角色'
          data.rowData = Object.assign({}, row)
          data.visible = true
          break
        }
        case 'del': {
          ElMessageBox.confirm(
            `确定要删除 ${row.roleName} 角色吗？`,
            '删除角色',
            {
              confirmButtonText: '确认删除',
              cancelButtonText: '取消',
              type: 'warning'
            }
          ).then(() => {
            deleteRole({ roleId: row.roleId }).then((res: any) => {
              ElMessage.success(res.msg)
              getListFn()
            })
          })
          break
        }
      }
    }

    // 关闭弹框
    const closeDialog = val => {
      data.visible = val
    }

    // 新增修改后刷新数据
    const refreshList = () => {
      tableConfig.pagination.currentPage = 1
      getListFn()
    }

    return {
      ...toRefs(data),
      handleSearch,
      handleReset,
      getListFn,
      closeDialog,
      tableConfig,
      btnHandle,
      pageCurrentChange,
      pageSizeChange,
      btnName,
      refreshList
    }
  },
  mounted() {
    this.getListFn()
  }
})
