import service from '@/utils/request'

// 列表数据查询
export const getRoleList = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/role/getRoleList',
    data: pramas
  })

// 添加员工
export const addRole = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/role/addRole',
    data: pramas
  })

// 删除员工
export const deleteRole = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/role/deleteRole',
    data: pramas
  })

// 修改员工
export const updateRole = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/role/updateRole',
    data: pramas
  })

// 权限
export const limitsFlattened = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/account/limitsFlattened',
    data: pramas
  })
// 权限
export const getRoleDetail = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/preset/role/detail',
    data: pramas
  })
