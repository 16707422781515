
import { defineComponent, reactive, toRefs } from 'vue'
// import { treeData } from '@/utils/index'
import { ElMessage } from 'element-plus'
import { addRole, updateRole, getRoleDetail } from './api'
import { getRolePermissionList } from '@/api/common'

export default defineComponent({
  props: {
    visible: Boolean,
    title: String,
    rowData: Object
  },
  setup(props, { emit }) {
    const data = reactive({
      formData: props.rowData,
      isDialog: props.visible,
      keys: [9, 10],
      data: [],
      treeProps: {
        label: 'title',
        value: 'name'
      }
    })

    const rules = {
      roleName: [{ required: true, message: '必填', trigger: 'blur' }],
      roleStatus: [{ required: true, message: '必填', trigger: 'blur' }]
    }
    const handleCloseDialog = () => {
      emit('close-dialog', false)
    }

    return {
      ...toRefs(data),
      rules,
      handleCloseDialog
    }
  },
  mounted() {
    this.getLimits()
  },
  methods: {
    getLimits() {
      // 获取功能权限数据
      getRolePermissionList({
        platformCode: 1
      }).then(res => {
        // const data = []
        // res.data.forEach(item => {
        //   this.data = r
        // //   data.push({
        // //     type: item.type,
        // //     id: item.labelId,
        // //     label: item.labelName,
        // //     parentId: item.parentId
        // //   })
        // // })
        this.data = res.data
        // this.data = treeData(data)
        if (this.title === '修改角色') {
          this.getDetail()
        }
      })
    },
    getDetail () {
      getRoleDetail({ roleId: this.formData.roleId }).then(res => {
        this.formData = res.data
        this.setChecked()
      })
    },
    setChecked() {
      // 修改设置勾选
      this.$nextTick(() => {
        console.log(this.formData)
        const permissions = this.formData.permissions
          ? JSON.parse(this.formData.permissions)
          : []
        const setCheckedKeys = []
        if (permissions.lenght !== 0) {
          permissions.forEach(item => {
            setCheckedKeys.push(item)
          })
        }
        this.$refs.tree.setCheckedKeys(setCheckedKeys)
      })
    },
    // 提交表单
    submitDialog() {
      this.$refs.ruleForm.validate((valid: boolean) => {
        if (valid) {
          // 勾选参数
          const permissions = []
          this.$refs.tree.getCheckedNodes().forEach(item => {
            if (item.type === 2) {
              permissions.push(item.name)
            }
          })

          const params: any = {
            roleName: this.formData.roleName,
            roleStatus: this.formData.roleStatus,
            permissions: JSON.stringify(permissions)
          }

          let methodFn = addRole
          if (this.formData.id) {
            params.id = this.formData.id
            params.roleType = this.formData.roleType
            params.platformCode = this.formData.platformCode
            methodFn = updateRole // 修改方法
          }
          methodFn(params).then((res: any) => {
            ElMessage.success(res.msg)
            this.handleCloseDialog()
            this.$emit('refreshList')
          })
        }
      })
    }
  }
})
